const isFullTimeWholeWeek = (data) => {
    const openingHours = [
        data['mon-fri_from'],
        data['mon-fri_to'],
        data['saturday_from'],
        data['saturday_to'],
        data['sunday_from'],
        data['sunday_to']
    ]
    return openingHours.every((el) => el === '00:00') ? 'całodobowo' : false
}

const isFullTime = (from, to) => {
    if (from === '00:00' && to === '00:00') {
        return 'całodobowo'
    } else {
        return `${from} - ${to}`
    }
}

const parseOpeningHours = (from, to, dayName) => {
    if (from && to) {
        return `<strong>${dayName}</strong>: ${isFullTime(from, to)}<br/>`
    } else {
        return `<strong>${dayName}</strong>: nieczynne<br/>`
    }
}

const openingHoursList = (data) => {
    return `
      ${parseOpeningHours(
          data['mon-fri_from'] || data['mon_fri_from'],
          data['mon-fri_to'] || data['mon_fri_to'],
          'poniedziałek - piątek'
      )}
    ${parseOpeningHours(data['saturday_from'], data['saturday_to'], 'sobota')}
    ${parseOpeningHours(data['sunday_from'], data['sunday_to'], 'niedziela')}
  `
}

const openingHours = (data) => {
    return isFullTimeWholeWeek(data)
        ? isFullTimeWholeWeek(data)
        : openingHoursList(data)
}

function convertToTitleCase(str) {
    if (!str) {
        return ''
    }

    str = str.replace('&quot;', '"')

    return str.replace(/([^\s",.]+)/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
}

function capitalizeAfterDot(str) {
    if (!str) {
        return ''
    }

    return str
        .split('.')
        .map((subStr) => {
            subStr = subStr.trimStart()
            return subStr.charAt(0).toUpperCase() + subStr.slice(1)
        })
        .join('. ')
        .trim()
        .replace(/Z O. O./g, 'z o.o.')
}

const pharmacyAddressOnly = (data) => {
    return `
     <h3>${
         data.name ? capitalizeAfterDot(convertToTitleCase(data.name)) : ''
     }</h3>
    ${data.street_address || data.street || ''} ${
        data.street_number || data.street_no || ''
    } ${data.house_number || ''}, ${data.city}
  `
}

const pharmacyAddressOnlyForSuggester = (data) => {
    if (data == undefined) {
      return ''
    }

    return `
     <span>${
         data.name ? capitalizeAfterDot(convertToTitleCase(data.name)) : ''
     }</span>,${' '}
    ${data.street_address || data.street || ''} ${
        data.street_number || data.street_no || ''
    }${data.house_number || ''}, ${data.city}
  `
}

const pharmacyAddress = (data) => {
    return `${pharmacyAddressOnly(data)}
    <h4>Godziny otwarcia:</h4>
    ${openingHours(data)}
  `
}
export {
    openingHours,
    pharmacyAddressOnly,
    pharmacyAddress,
    pharmacyAddressOnlyForSuggester
}
