import axios from 'axios'
import { EventBus, SET_LOADER } from '../utils/event-bus.js'

axios.interceptors.request.use(
    function (config) {
        EventBus.$emit(SET_LOADER, true)
        return config
    },
    function (error) {
        EventBus.$emit(SET_LOADER, false)
        return Promise.reject(error)
    }
)

axios.interceptors.response.use(
    function (response) {
        EventBus.$emit(SET_LOADER, false)
        return response
    },
    function (error) {
        EventBus.$emit(SET_LOADER, false)
        return Promise.reject(error)
    }
)

const noCache = {
    headers: {
        'Cache-Control': 'no-cache, max-age=0, must-revalidate, no-store',
        Pragma: 'no-cache',
        Expires: '0'
    }
}

/**
 * @name setUser
 * @desc Save billing/shipping data to API
 * @params {Object} data
 * @returns {Object} response
 */
const setUser = async (data) => {
    return await axios
        .post(BASE_URL + 'checkout/onepage/saveBilling/', data, noCache)
        .catch((error) => {
            console.error(error)
            return Promise.reject(error)
        })
}

/**
 * @name getCustomer
 * @desc Get customer data
 * @returns {Promise<AxiosResponse<any>>}
 */
const getCustomer = async () => {
    return await axios
        .get(BASE_URL + 'checkout/onepage/customer', noCache)
        .catch((error) => {
            console.error(error)
            return Promise.reject(error)
        })
}

/**
 * @name getCartContent
 * @desc Get cart content methods from API
 * @returns {Object}
 */
const getCartContent = async () => {
    return await axios
        .get(BASE_URL + 'checkout/onepage/getCartContent/', noCache)
        .then((response) => response.data)
        .catch((error) => {
            console.error(error)
            return Promise.reject(error)
        })
}

/**
 * @name createOrder
 * @desc create and send preorder to NEUCA API
 * @returns {Object} response
 */
const createOrder = async () => {
    return await axios
        .get(BASE_URL + 'checkout/onepage/createOrder/', noCache)
        .catch((error) => {
            console.error(error)
            return Promise.reject(error)
        })
}

/**
 * @name sendOrder
 * @desc save order to Magento
 * @returns {Object} response
 */
const saveOrder = (data) => {
    return axios
        .post(BASE_URL + 'checkout/onepage/saveOrder/', data, noCache)
        .catch((error) => {
            console.error(error)
            return Promise.reject(error)
        })
}

/**
 * @name gtmStepUpdate
 * @desc Update checkout option gtm for each step
 * @param step
 * @param option
 */
const gtmStepUpdate = (step, option) => {
    window.dataLayer.push({
        event: 'checkoutOption',
        ecommerce: {
            checkout_option: {
                actionField: { step: step, option: option }
            }
        }
    })
}

/**
 * @name gtmPurchase
 * @desc Purchase event for google tag manager
 * @param order
 * @param responseOrderData
 */
const gtmPurchase = (order, responseOrderData) => {
    var dataLayer = (window.dataLayer = window.dataLayer || [])

    if (Cookies.get('order_id_check') === responseOrderData.order_id) {
        return
    }

    Cookies.set('order_id_check', responseOrderData.order_id)

    if (responseOrderData.customer_registered != undefined && responseOrderData.customer_registered != '') {
      dataLayer.push({
        event: "register",
        userId: responseOrderData.customer_registered
      });
      Mage.Cookies.set('garegister', '', null, '/', responseOrderData.cookie_domain);
      Mage.Cookies.set('galogin', '', null, '/', responseOrderData.cookie_domain);
    }

    var dlUpdate = {
        event: 'transaction',
        ecommerce: {
            purchase: {
                actionField: {
                    id: responseOrderData.order_id,
                    revenue: order.total
                        .replace(/[^0-9.,]+/g, '')
                        .replace(',', '.'), // remove currency and change separator to dot
                    shipping: order.shipmentCost
                },
                products: []
            }
        }
    }

    if (order.order_data) {
        for (const [k, v] of Object.entries(order.order_data)) {
            if (v.available || (!v.available && v.priceChanged)) {
                dlUpdate.ecommerce.purchase.products.push({
                    id: k,
                    name: v.name,
                    price: v.unitPrice,
                    quantity: v.qty,
                    brand: v.brand,
                    category: v.category,
                    dimension11: v.productLabels
                })
            }
        }
    }

    if (order.items) {
      for (const [k, v] of Object.entries(order.items)) {
        dlUpdate.ecommerce.purchase.products.push({
          id: v.id,
          name: v.name,
          price: v.unitPrice,
          quantity: v.qty,
          brand: v.brand,
          category: v.category,
          dimension11: v.productLabels
        })
      }
    }

    dataLayer.push(dlUpdate)

    dataLayer.push({
        event: 'checkoutSuccess',
        'transaction-id': responseOrderData.order_id,
        'transaction-total': order.total
    })
}

/**
 * @name gtmStep
 * @desc Update gtm for each step
 * @param step
 * @param {object} cart
 */
const gtmStep = (step, cart) => {
    if (!cart) return
    let dataLayer = (window.dataLayer = window.dataLayer || []),
        currency = (window.dlCurrencyCode = window.dlCurrencyCode || 'PLN'),
        skus = '',
        quantity = 0

    let dlUpdate = {
        event: 'checkout',
        ecommerce: {
            currencyCode: currency,
            checkout: {
                actionField: {
                    step: step
                },
                products: []
            }
        }
    }

    for (let i = 0; i < cart.length; i++) {
        let product = cart[i]
        if (product.is_available === 0) {
            continue
        }

        dlUpdate.ecommerce.checkout.products.push({
            id: product.id,
            name: product.name,
            price: product.price,
            quantity: product.qty,
            category: product.category,
            brand: product.brand
        })

        if (skus) {
            skus = skus + ', ' + product.id
        } else {
            skus = product.id
        }
        quantity = quantity + product.qty
    }

    let dlUpdateVariable = {
        event: 'checkoutVariables',
        'product-skus': skus,
        'checkout-step': step,
        'product-quantity': quantity
    }

    dataLayer.push({
        event: 'clearProducts',
        ecommerce: {
            checkout: {
                products: null
            }
        }
    })
    dataLayer.push(dlUpdate)
    dataLayer.push(dlUpdateVariable)
}

const errorMessageCode = (code) => {
    switch (code) {
        case 100:
            return 'Wybrana apteka jest zablokowana. Zmień aptekę i ponów zamówienie.'
        case 200:
            return 'Wybrany produkt został zablokowany lub jest nieaktywny.'
        case 210:
            return 'Wybrana apteka nie ma uprawnień do sprzedaży produktów wyróżnionych poniżej.'
        case 220:
        case 230:
        case 231:
        case 233:
        case 234:
            return 'Zaznaczone niżej produkty są chwilowo niedostępne.'
        case 232:
        case 400:
            return 'Przepraszamy. Cena oznaczonego produktu uległa zmianie. Sprawdź poniżej aktualną cenę.'
        case 401:
            return 'Cena oznaczonego produktu nie została odnaleziona.'
        case 410:
            return 'Poziom refundacji oznaczonego produktu uległ zmianie.'
        case 1010:
            return 'Podczas składania zamówienia wystąpił błąd. Zweryfikuj czy masz prawidłową liczbę produktów w koszyku i kontynuuj zamówienie.'
        case 1020:
            return 'Produktów składowych zestawu promocyjnego nie można zamawiać jednocześnie z zestawem.'
    }
}

export {
    setUser,
    getCustomer,
    getCartContent,
    createOrder,
    saveOrder,
    errorMessageCode,
    gtmStepUpdate,
    gtmPurchase,
    gtmStep
}
